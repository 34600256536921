@header-bg-color: @color-white;
@header-text-color: @color-black;
@header-border-color: var(--color-first);
@header-margin-bottom: 63px;
@header-mobile-bg-color:@color-white;
@header-mobile-border-color: var(--color-first);
@header-padding-top: 0;

header {
	.clearfix();
	background:@header-bg-color;
	color:@header-text-color;

	@media (min-width:@screen-md-min) {
		padding-top: @header-padding-top;
	}

	body:not(.frontpage) & {
		margin-bottom: var(--header-margin-bottom, @header-margin-bottom);

		@media (min-width:@screen-md-min) {
			border-bottom: 1px solid @header-border-color;
		}
	}

	 body.bannerLayout5 & {
		background:var(--color-first);
		color:@color-white;
	}

	> .contentWrap {
		@media (min-width:@screen-md-min) {
			display: flex;
			align-items: center;

			.skoli &:last-child {
				margin-bottom: 8px;
			}
		}

		// Mobile header
		@media (max-width:@screen-sm-max) {
			position:fixed;
			z-index:@zindex-header;
			top:0;
			left:0;
			width:100%;
			height:@header-height-mobile;
			background:@header-mobile-bg-color;
			border-bottom:1px solid @header-mobile-border-color;
			margin:0;

			.skoli & {
				border-bottom:0;
				box-shadow: 0 9px 7px rgba(0, 0, 0, 0.05);
			}

			body.loggedIn & {
				top:31px;
			}
		}
	}

	.logo {
		position: relative;
		z-index: @zindex-header-logo;

		@media (min-width:@screen-md-min) {
			flex: 0 1 auto;
			padding-right: 30px;
		}
	}
}
