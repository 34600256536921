@breadcrumb-default: true;

& when (@breadcrumb-default = true) {
	#breadcrumbsWrap {
		display: flex;
		align-items: center;
		min-height: var(--breadcrumbs-wrap-min-height);
		margin: var(--breadcrumbs-wrap-margin, 0 0 35px);
		border-top: var(--breadcrumbs-wrap-border-top);
		border-bottom: var(--breadcrumbs-wrap-border-bottom);
		background-color: var(--breadcrumbs-wrap-background-color);

		> .contentWrap {
			display: flex;
			align-items: center;
		}
	}

	#breadcrumbs {
		font-size: var(--breadcrumbs-font-size, 14px);
		font-weight: var(--breadcrumbs-font-weight, var(--font-weight-medium));
		text-transform: var(--breadcrumbs-text-transform, uppercase);

		@media (max-width: 991px) {
			display: var(--breadcrumbs-mobile-display, none);
		}

		.sep {
			color: var(--breadcrumbs-sep-color, var(--color-first));
			padding: var(--breadcrumbs-sep-padding, 0 5px);
		}

		a {
			color: var(--breadcrumbs-link-color, var(--color-first));

			&:hover {
				color: var(--breadcrumbs-link-color-hover, var(--color-second));
			}
		}

		.last:not(.first) {
			font-weight: var(--breadcrumbs-last-font-weight);
			color: var(--breadcrumbs-last-color, #333);
			pointer-events: var(--breadcrumbs-last-pointer-events, none);

			&:hover {
				color: var(--breadcrumbs-last-color-hover, #333);
			}
		}

		a,
		.sep {
			&:last-of-type:not(.first) {
				display: var(--breadcrumbs-last-item-display);
			}
		}
	}
}
