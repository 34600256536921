@megamenu-position-top: 133px;
@megamenu-box-shadow: 1px 20px 32px rgba(0, 0, 0, 0.07);
@megamenu-contentwrap-padding: 23px 32px 20px;
@megamenu-contentwrap-padding-frontpage: 55px;
@megamenu-level2-border-color: var(--color-first);
@megamenu-column-count: 4;
@megamenu-column-gap: 50px;
@megamenu-level2-lines: 2;
@megamenu-level2-font-size: 2;
@megamenu-level2-line-height: 1.25;
@megamenu-level2-link-color: @color-text;
@megamenu-level2-link-hover-color: var(--color-first);
@megamenu-level3-font-size: 18px;
@megamenu-level3-link-color: @color-text;
@megamenu-level3-link-hover-color: var(--color-first);
@megamenu-level3-margin-bottom: 1px;

#mainmenu {
	@media (min-width: @screen-md-min) {
		.mmLevel1 > .level1 {
			position: static !important;

			body.tabbing &:not(.open) .megamenu {
				display: none;
			}

			&.open {
				.megamenu {
					transition: opacity .4s ease;
				}
			}

			&:not(.open) {
				.megamenu {
					pointer-events: none;
					opacity: 0;
				}
			}
		}
	}
}

.megamenu {
	position: absolute;
	z-index: @zindex-mainmenu;
	top: @megamenu-position-top;
	right: 15px;
	left: 15px;
	overflow: hidden;
	box-shadow: @megamenu-box-shadow;
	transition: opacity .4s ease .2s;

	@media (max-width: @screen-sm-max) {
		display: none!important;
	}

	.wrap {
		position: relative;
		background: @color-white;

		> .contentWrap {
			padding: @megamenu-contentwrap-padding;

			body.frontpage & {
				padding-top: @megamenu-contentwrap-padding-frontpage;
			}
		}
	}

	.mmLevel2 {
		width: 100%;
		column-count: @megamenu-column-count;
		column-gap: @megamenu-column-gap;

		a {
			display: block;
			color: @megamenu-level2-link-color;

			&:hover {
				color: @megamenu-level2-link-hover-color;
			}
		}

		li[class*=selectedLevel] {
			> a {
				font-weight: 600;
				color: var(--color-first);
			}
		}

		.level2 {
			margin-bottom: 27px;
			page-break-inside: avoid;
			break-inside: avoid;

			@media (max-width: @screen-sm-max) {
				&:not(.children) {
					margin-bottom: 0;

					> a {
						border-bottom: 0;
					}
				}
			}

			> a {
				display: flex;
				align-items: flex-end;
				flex-direction: row;
				min-height: 55px;
				padding-bottom: 7px;
				margin-bottom: 12px;
				font-weight: 600;
				color: @megamenu-level2-link-color;
				border-bottom: 1px solid transparent;

				&:hover {
					color: var(--color-first);
				}

				span {
					.ellipsisMax(
						@megamenu-level2-lines,
						@megamenu-level2-font-size,
						@megamenu-level2-line-height
					);
				}
			}

			&.children > a {
				border-color:@megamenu-level2-border-color;
			}

			.mmLevel3 {
				.level3 {
					> a {
						font-size: @megamenu-level3-font-size;
						color:@megamenu-level3-link-color;

						&:hover {
							color: @megamenu-level3-link-hover-color;
						}
					}

					&:not(:last-child) {
						margin-bottom: @megamenu-level3-margin-bottom;
					}
				}
			}

			.mmLevel4 {
				display: none;
			}
		}
	}
}
