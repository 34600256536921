.frontpage {
	.box {
		.boxTitle {
			h2 {
				font-size:22px;
				font-family:var(--font-family-headings);
				font-weight:var(--font-weight-semibold);
				text-transform: uppercase;
				margin-bottom: 60px;

				&:after {
					content:'';
					display:block;
					border-bottom:2px solid var(--color-first);
					max-width:41px;
					margin-top:14px;
				}
			}

			.subHeadline {
				text-transform: uppercase;
				font-size: 16px;
				color: #666;

				&+h2 {
					margin-top: 5px;
				}
			}
		}
	}
}

h1 {
	position: relative;
	font-weight:var(--font-weight-semibold);
	padding-left:30px;

	&:before {
		content: "";
		position: absolute;
		left:0;
		top: 2px;
		bottom: 9px;
		border-left: 3px solid var(--color-first);
	}
}
