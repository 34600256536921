.icon__box {
	.bannerLayout6 & {
		@media (min-width:@screen-md-min) {
			.link {
				width: 33%;

				a {
					padding: 0 15px;
					flex-direction: row;
					align-items: center;

					&:hover {
						.icon {
							background:var(--color-first);
							color:@color-white;
						}
					}
				}

				.icon {
					flex: 0 0 auto;
					margin: 0 15px 0;
					background:none;
					color:var(--color-first);
				}
			}
		}
	}
}
