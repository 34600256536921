@search-autocomplete-border-color: @color-border-light;
@search-autocomplete-bg-color: @color-white;
@search-autocomplete-header-bg-color: #f7f7f7;
@search-autocomplete-header-text: @color-black;
@search-autocomplete-result-hover-bg: #f3f3f3;
@search-autocomplete-link-color: @link-color;
@search-autocomplete-link-hover-color: @link-hover-color;
@search-autocomplete-icon-color: @link-color;

.search__autocomplete {
	.results {
		position: relative;

		.wrap {
			position: absolute;
			top: 1px;
			left: -1px;
			right: -1px;
			z-index: 1;
			background: @search-autocomplete-bg-color;
			border-left: 1px solid @search-autocomplete-border-color;
			border-right: 1px solid @search-autocomplete-border-color;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			max-height: 500px;
			overflow-y: auto;
		}
	}

	.header {
		display: block;
		background: @search-autocomplete-header-bg-color;
		border-bottom: 1px solid @search-autocomplete-border-color;

		.result__label {
			display: block;
			overflow: hidden;
			color: @search-autocomplete-header-text;
		}

		.result__title {
			display:block;
			font-size: 18px;
			font-weight: 600;
			padding-left: 20px;
			padding-top: 20px;
			padding-bottom: 19px;

			@media (min-width:@screen-sm-min) {
				float: left;
			}
		}

		.result__seeAll {
			@media (min-width: @screen-sm-min) {
				padding: 18px 20px 21px;
				border-left: 1px solid @search-autocomplete-border-color;
				float: right;

			}
			@media (max-width: @screen-xs-max) {
				display: block;
				border: 0;
				padding-left: 18px;

			}

			span {
				font-weight: @font-weight-bold;
			}

			&:hover {
				color: @color-first;
			}
		}
	}

	.result {
		border-bottom: 1px solid @search-autocomplete-border-color;

		&.active {
			background: @search-autocomplete-result-hover-bg;
		}

		a {
			display: flex;
		}

		.result__label {
			padding: 10px;
			border-left: 1px solid @search-autocomplete-border-color;
		}
	}

	.result__type {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: @search-autocomplete-icon-color;
		min-width: 60px;
		max-width: 60px;
		text-align: center;

		@media (max-width: @screen-xs-max) {
			min-width: 40px;
			max-width: 40px;
		}

		&:before {
			.fas();
			content:@fa-var-arrow-circle-right;
		}
	}

	.type-news .result__type:before {
		content:@fa-var-newspaper;
	}
	.type-meeting .result__type:before {
		content:@fa-var-gavel;
	}
	.type-employees .result__type:before {
		content:@fa-var-user;
	}
	.type-file .result__type:before {
		content:@fa-var-file;
	}
	.type-calendar .result__type:before {
		content:@fa-var-calendar;
	}
	.type-gallery .result__type:before {
		content:@fa-var-camera-retro;
	}
	.type-page .result__type:before {
		content:@fa-var-paperclip;
	}
	.type-extras .result__type:before {
		content:@fa-var-paperclip;
	}

	a {
		color:@search-autocomplete-link-color;

		&:focus,
		&:hover {
			color:@search-autocomplete-link-hover-color;
		}
	}
}
