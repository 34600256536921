@phoenix-skoli-breadcrumbs-background: #f7f7f7;
@phoenix-skoli-breadcrumbs-margin-bottom: 54px;

#contentContainer {
	> #breadcrumbsWrap {
		background: @phoenix-skoli-breadcrumbs-background;
		border-bottom:1px solid #eee;
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: @phoenix-skoli-breadcrumbs-margin-bottom;

		> #breadcrumbs {
			&:extend(.contentWrap);
			margin-bottom:0;
			text-transform: none;
		}
	}
}
