#mSearchBox {
	.hidden-lg;
	.hidden-md;
	padding: 29px;
	background-color: @color-white;
	#mSearchForm {
		position: relative;
	}

	.elm {
		margin:0;
	}

	#mElm_submit {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		height: 100%;

		&:before {
			content: '';
			mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94173 0C9.95296 0 11.8818 0.798956 13.304 2.22111C14.7261 3.64326 15.5251 5.57211 15.5251 7.58333C15.5251 9.46167 14.8367 11.1883 13.7051 12.5183L14.0201 12.8333H14.9417L20.7751 18.6667L19.0251 20.4167L13.1917 14.5833V13.6617L12.8767 13.3467C11.5467 14.4783 9.82007 15.1667 7.94173 15.1667C5.93051 15.1667 4.00166 14.3677 2.57951 12.9456C1.15735 11.5234 0.358398 9.59456 0.358398 7.58333C0.358398 5.57211 1.15735 3.64326 2.57951 2.22111C4.00166 0.798956 5.93051 0 7.94173 0ZM7.94173 2.33333C5.02507 2.33333 2.69173 4.66667 2.69173 7.58333C2.69173 10.5 5.02507 12.8333 7.94173 12.8333C10.8584 12.8333 13.1917 10.5 13.1917 7.58333C13.1917 4.66667 10.8584 2.33333 7.94173 2.33333Z' fill='%23E30613'/%3E%3C/svg%3E");
			display:block;
			background: var(--color-first);
			height: 21px;
			width: 21px;
			transition: color .4s ease;
			background-repeat: no-repeat;
			position: absolute;
			top: 17px;
			right: 18px;
			body.searchbox-open & {
				color:var(--color-second);
			}
		}
	}
	#mSearchSubmit {
		position: absolute;
		height: 50px;
		width: 45px;
		border: 0;
		padding: 0;
		margin: 0;
		cursor: pointer;
		text-indent: -9999em;
		font-size: 0;
		background: none;
		top: 0;
		right: 0;
		line-height: 0;
		color:var(--color-first);
	}

	#mQ {
		display:block;
		background:none;
		min-width: 0;
		height: auto;
		font-size: 16px;
		background: #F2F2F2;
		border-radius: 50px;
		border: 0;
		width: 100%;
		min-height:50px;
		color:@color-text-light;
		padding: 5px 40px 6px 15px;
		opacity:1;
		box-shadow: unset;
	}

	label[for="mQ"] {
		&:extend(.sr-only);
	}
}
