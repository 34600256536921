#news {
	.entries.all {
		.entry {
			.entryImage {
				@media (min-width: @screen-sm-min) {
					max-width: 345px;
					margin: 0 0 28px 30px;
					clear: right;
					float: right!important;
				}
				img {
					@media (min-width: @screen-sm-min) {
						float: right;
						margin-left: 40px;
					}
				}
			}

			.entryTitle {
				> a {
					color: @color-black;
					transition: color .4s ease;
					&:hover {
						color: var(--color-first);
					}
				}
			}

			&.hasImage {
				img {
					padding-bottom: 20px!important;
				}
			}

			@media (max-width: @screen-xs-max) {
				.entryImage {
					img {
						width: 100%;
						padding-bottom: 10px;
					}
				}
				.entryTitle {
					font-size: 20px;
				}
			}
		}
	}

	.entry {
		.entryImage {
			.entryImageText {
				font-size: 16px;
				margin-top: 10px;

				@media(max-width: @screen-xs-max) {
					margin-bottom: 18px;
				}
			}
		}
	}
}
