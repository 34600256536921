@sidebar-title-font-size: 14px;
@sidebar-title-font-weight: var(--font-weight-semibold);
@sidebar-title-color: var(--color-first);
@sidebar-title-transform: uppercase;
@sidebar-margin-top: 115px;
@sidebar-max-width: 305px;

#sidebar {
	@media (min-width: @screen-md-min) {
		margin-top: @sidebar-margin-top;
	}

	@media (max-width: @screen-sm-max) {
		margin-top: 40px;
	}

	.box {
		@media (min-width: @screen-md-min) {
			float: right;
			width: 100%;
			max-width: @sidebar-max-width;
		}

		@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
			max-width: 50%;
		}

		a {
			color: @color-text-dark;

			&:hover {
				color: var(--color-first);
			}
		}

		.boxTitle {
			h2 {
				padding-bottom: 9px;
				margin-bottom: 18px;

				font-size: @sidebar-title-font-size;
				font-weight: @sidebar-title-font-weight;
				color: @sidebar-title-color;
				text-transform: @sidebar-title-transform;

				border-bottom: 1px solid;
			}
		}

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		&.gray {
			padding: 18px 0 36px;

			background: #e8e8e8;

			.boxTitle {
				margin-bottom: 20px;

				&:after {
					content: '';

					display: block;

					max-width: 63px;

					border-bottom: 1px solid var(--color-first);
				}

				h2 {
					margin-bottom: 0;

					border-bottom: 0;
				}
			}

			.boxTitle h2,
			.boxText {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}
}
