@phoenix-frontpage-contentWrap-max-width: none;

html {
	height:101%;
}

body {
	-webkit-font-smoothing:antialiased;
	min-width:305px;
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	flex-direction: column;

	&#tinymce, &.mceContentBody {
		display:block;
		flex-direction: initial;
	}
}

.mm-page {
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	flex-direction: column;
}

#page {
	flex: 1 0 auto;
	position:relative;

	//IE fix
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		min-height:92vh;
	}
	// Move content down so the mobile header does not block it
	@media only screen and (max-width:@screen-sm-max) {
		padding-top:@header-height-mobile;
	}
}

#contentContainer {
	padding-bottom:50px;

	@media (min-width: @screen-md-min) {
		.skoli & {
			min-height: 450px;
		}
	}
	@media (max-width:@screen-sm-max) {
		body:not(.frontpage) & {
			padding-top: 20px;
		}
	}

	> .contentWrap {
		.clearfix();

		.frontpage & {
			max-width:@phoenix-frontpage-contentWrap-max-width;
		}
	}
}

#main {
	.make-md-column(9);

	body.hideright & {
		.make-md-column(12);
	}
	body.frontpage & {
		.make-md-column(12, 0);
	}
}

#sidebar {
	.make-md-column(3);
	.noprint;

	body.hideright &,
	body.frontpage & {
		&:extend(.hidden);
	}
}
