@mobilemenu-background-color: #fff;
@mobilemenu-position-top: @header-height-mobile;
@mobilemenu-position-left: auto;
@mobilemenu-position-right: 0;
@mobilemenu-position-top-loggedIn: 31px;
@mobilemenu-padding-top: 0;
@mobilemenu-z-index: @zindex-header + 1;
@mobilemenu-overlay-top: 0;
@mobilemenu-overlay-z-index: @zindex-header - 1;
@mobilemenu-overlay-background: rgba(0,0,0, 0.3);
@mobilemenu-overlay-transition: opacity .4s ease;
@mobilemenu-overlay-media-query: ~"(max-width: @{mobilemenu-start-width})";
@mobilemenu-start-width: @screen-sm-max;
@mobilemenu-max-width: 320px;
@mobilemenu-transform: translateX(100%);
@mobilemenu-transition: transform .4s ease;

@media @mobilemenu-overlay-media-query {
	#page {
		&:after {
			content: '';
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			top: @mobilemenu-overlay-top;
			z-index: @mobilemenu-overlay-z-index;
			background: @mobilemenu-overlay-background;
			transition: @mobilemenu-overlay-transition;
			pointer-events: none;

			body:not(.mainmenu-open) & {
				opacity: 0;
			}
		}
	}

	body {
		&.mainmenu-open {
			overflow: hidden;
		}
	}
}

#mainmenu {
	@media (max-width: @mobilemenu-start-width) and (min-width:@screen-xs-min) {
		max-width: @mobilemenu-max-width;
	}
	@media (max-width: @mobilemenu-start-width) {
		position: fixed;
		top: @mobilemenu-position-top;
		left: @mobilemenu-position-left;
		right: @mobilemenu-position-right;
		bottom: 0;
		z-index: @mobilemenu-z-index;
		width: 100%;
		background: @mobilemenu-background-color;
		overflow-y: auto;
		padding-top: @mobilemenu-padding-top;
		transition: @mobilemenu-transition;

		body:not(.mainmenu-open) & {
			transform: @mobilemenu-transform;
		}
		body:not(.mainmenu-open):not(.mainmenu-closing) & {
			opacity:0;
			visibility: hidden;
		}
		.loggedIn & {
			margin-top: @mobilemenu-position-top-loggedIn;
		}
	}
}
