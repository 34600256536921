/** Color palette **/
:root {
	--color-first: #00757f;
	--color-second: darken(#00757f, 9.5%);
	--color-third: #000;
	--font-family-body: "Hind", Helvetica, Arial, sans-serif;
	--font-family-headings: "Bitter", Helvetica, Arial, sans-serif;
}

/* @deprecated */
@color-first:#000;

@color-text:#333;
@color-text-light:#666;
@color-text-dark:#000;

@color-border:#ccc;
@color-border-dark:darken(@color-border, 20%);
@color-border-light:lighten(@color-border, 9.5%);
@color-border-faded:fade(@color-border, 50%);

@color-black:#000;
@color-white:#fff;
@color-gray:#ececec;
@color-hero-bg:#757575;

/** Z index **/
@zindex-header:10;
@zindex-header-logo:31;
@zindex-divisions:20;
@zindex-mainmenu:30;


/** Other **/
@site-width:1470px;
@header-height-mobile:61px;
@font-weight-bold:var(--font-weight-bold);
@default-margin:round(@font-size-base * @line-height-base);

/** Override bootstrap vars **/
@body-bg:var(--body-bg, #f8f8f8);
@text-color:@color-text;
@brand-primary:@color-black;
@input-border-focus:transparent;

/** Links **/
@link-hover-decoration:none;

/** Fonts **/
@font-family-sans-serif:var(--font-family-body);
@font-size-base:18px;
@line-height-base:1.556;

/** Headings styles **/
//@headings-font-family:var(--font-family-headings);
//@headings-font-weight:700;
//@headings-line-height:1.1;
@headings-color:@color-text;
@font-size-h1: 40px;
@font-size-h2: 26px;
@font-size-h3: 22px;
@font-size-h4: 18px;
@font-size-h5: 16px;
@font-size-h6: 14px;

/** Tables **/
@table-bg:transparent;
@table-bg-accent:rgba(0, 0, 0, .2);
@table-bg-head:rgba(0, 0, 0, .5);
@table-border-color:rgba(0,0, 0, .8);


/** Global padding **/
//@padding-base-vertical:10px;
//@padding-base-horizontal:16px;


/** Global border-radius **/
//@border-radius-base:0px;
//@border-radius-large:0px;
//@border-radius-small:0px;


/** Buttons **/
@btn-font-weight:var(--font-weight-normal);

@btn-default-color:@color-text-dark;
@btn-default-bg:transparent;
@btn-default-border:@color-text-dark;

@btn-primary-color:#fff;
@btn-primary-border:transparent;

@btn-info-color:#fff;
@btn-info-bg:transparent;
@btn-info-border:#fff;

@subpage-banner-max-height: 300px;
@mobilemenu-padding-top: 60px;
@breadcrumb-background-color:none;
