*:active, *:focus {
	body:not(.tabbing) & {
		outline: none!important;
	}
	body.tabbing & {
		outline-width:2px!important;
		outline-style:solid!important;
		outline-color:var(--color-second);
		outline-offset: -2px;
	}
}

#skipToMain {
	position: absolute;
	left: 0;
	top: 0;
	background: var(--color-first);
	color: @color-white;
	z-index: 99;
	padding: 8px 15px 9px;
	font-weight: var(--font-weight-semibold);
	outline-color: @color-white;

	&:focus {
		opacity:1;
	}
}
