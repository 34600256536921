@mainmenu-background-color:var(--color-first);
@mainmenu-box-shadow: inset 0 3px 6px 0 rgba(0,0,0,.2);
@mainmenu-selected-color: var(--color-first);
@mainmenu-selected-background-color: none;
@mainmenu-padding-right:62px;
@mainmenu-level1-margin-right: 28px;
@mainmenu-justify-content: flex-end;
@mainmenu-margin-top: 0;

#mainmenuToggle {
	position:fixed;
	z-index:98;
	top: 0;
	right: 0;
	width: @header-height-mobile;
	height: @header-height-mobile;
	padding: 13px 0;
	cursor: pointer;
	background: none;
	border: 0;
	.visible-xs-block();
	.visible-sm-block();

	.loggedIn & {
		top:31px;
	}

	span {
		display: block;
		width: @header-height-mobile / 2;
		height: 3px;
		margin: 4px auto;
		background: var(--color-first);
		transition: transform .4s ease, opacity .4s ease;

		.mainmenu-open & {
			&.first {
				transform: translateY(8px) rotate(45deg);
			}
			&.middle {
				opacity:0;
			}
			&.last {
				transform: translateY(-6px) rotate(-45deg);
			}
		}
	}
}

#mainmenu {
	@media (min-width:@screen-md-min) {
		// Removed so megamenu can stretch across the site:
		//position: relative;
		flex: 1 1 auto;
		margin-top: @mainmenu-margin-top;

		.skoli .contentWrap > & {
			margin-top: 34px;
		}
	}

	.mmLevel1 {
		@media (min-width:@screen-lg-min) {
			> li {
				&:not(:first-child) {
					margin-left:@mainmenu-level1-margin-right;
				}
			}
		}

		@media (min-width:@screen-md-min) {
			display: flex;
			justify-content:@mainmenu-justify-content;
			padding-right:@mainmenu-padding-right;

			> li {
				> a {
					display:inline-block;
					color:currentColor;
					text-align:center;
					padding:12px 10px 17px;

					.skoli & {
						text-transform: uppercase;
						font-weight:var(--font-weight-semibold);
					}

					header > & {
						color:@color-white;
						padding:19px 10px 16px;
					}
				}

				> a:hover,
				&.open > a,
				&.selectedLevel1 > a {
					color:@mainmenu-selected-color;
					background:@mainmenu-selected-background-color;

					body.bannerLayout5 & {
						color:@color-black;
					}
				}

				&.qn {
					display:none;
				}

				// Default behavior is to show only first level in mainmenu
				> ul {
					display:none;
				}
			}
		}
	}

	.mmLevel1 {
		> .children {
			> a {
				position: relative;
				> span {
					padding-right: 10px;
					&:before {
						content: '';
						position: absolute;
						right: 0;
						top: 50%;
						margin-top: -9px;
						-webkit-mask: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='none'%3E%3Cpath fill='%23B41E28' d='M1.41.79 6 5.38 10.59.79 12 2.21l-6 6-6-6L1.41.79Z'/%3E%3C/svg%3E");
						background: var(--color-first);
						height: 9px;
						width: 12px;
						-webkit-mask-repeat: no-repeat;
						display: inline-block;
					}
				}
			}
		}
	}

	@media (min-width:@screen-md-min) {
		.ele-language,
		.expand {
			display: none;
		}
	}

	header > & {
		background:@mainmenu-background-color;
		box-shadow: @mainmenu-box-shadow;
	}
}
