@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: normal;
	src: url('@{fa-font-path}/fa-brands-400.eot');
	src: url('@{fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
	url('@{fa-font-path}/fa-brands-400.woff2') format('woff2'),
	url('@{fa-font-path}/fa-brands-400.woff') format('woff'),
	url('@{fa-font-path}/fa-brands-400.ttf') format('truetype'),
	url('@{fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	src: url('@{fa-font-path}/fa-regular-400.eot');
	src: url('@{fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
	url('@{fa-font-path}/fa-regular-400.woff2') format('woff2'),
	url('@{fa-font-path}/fa-regular-400.woff') format('woff'),
	url('@{fa-font-path}/fa-regular-400.ttf') format('truetype'),
	url('@{fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	src: url('@{fa-font-path}/fa-solid-900.eot');
	src: url('@{fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
	url('@{fa-font-path}/fa-solid-900.woff2') format('woff2'),
	url('@{fa-font-path}/fa-solid-900.woff') format('woff'),
	url('@{fa-font-path}/fa-solid-900.ttf') format('truetype'),
	url('@{fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fab() {
	font-family: 'Font Awesome 5 Brands';
}
.fas() {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900!important;
}
.far() {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

.fab:before {
	.fab();
}

.fas:before,
.fa:before {
	.fas();
}
.far:before {
	.far();
}

//FA5 variable rename fixes
@fa-var-calendar-o:@fa-var-calendar;
@fa-var-reorder:@fa-var-bars;
@fa-var-long-arrow-down:@fa-var-long-arrow-alt-down;
@fa-var-long-arrow-up:@fa-var-long-arrow-alt-up;
@fa-var-reorder:@fa-var-bars;
@fa-var-newspaper-o:@fa-var-newspaper;
@fa-var-photo:@fa-var-image;

//FA5 icon prefix fixes
div.elm_datePicker .lmnt,
#search #elm_submit,
#search ol.items .type,
#sortList .ui-sortable-handle,
div.sortOptions a.title,
#employees.box .staff div.content ul.extra li {
	&:before {
		.fas();
	}
}

div.fancybox-outer .fancybox-nav {
	&.fancybox-prev span:before {
		content:@fa-var-chevron-left;
	}
	&.fancybox-next span:before {
		content:@fa-var-chevron-right;
	}
	span:before {
		.fas();
		width: 100%;
		font-size: 25px!important;
		padding-top: 12px;
	}
}

//Tinymce style fixes
body#tinymce {
	*{
		&.fab,
		&.fas,
		&.far,
		&.fa {
			display: inherit;
			line-height: inherit;
		}
	}
}
