header {
	.shadow {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 8px;
			box-shadow: 0 9px 7px rgba(0, 0, 0, 0.05);
			z-index: 1;
		}
	}
}
