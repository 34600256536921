@frontpage-bg-color: @color-white;
@frontpage-box-color: @color-white;
@frontpage-gray-color: none;
@frontpage-padding-value: 35px;
@frontpage-lastbox-margin-bottom: -50px;

.frontpage {
	background:@frontpage-bg-color;

	#main {
		> .box {
			background:@frontpage-box-color;
			padding-top:@frontpage-padding-value;
			padding-bottom:@frontpage-padding-value;

			&:first-child {
				padding-top: @frontpage-padding-value * 2;
			}

			&.full {
				> .contentWrap {
					max-width:none;
					padding-left:0;
					padding-right:0;
				}
			}

			&.skip-padding {
				padding-top:0;
				padding-bottom:0;
				margin-top:@frontpage-padding-value;
				margin-bottom:@frontpage-padding-value;
			}

			&+.box {
				margin-top:0;
			}

			&:nth-child(even) {
				background:@frontpage-gray-color;

				&:last-child {
					margin-bottom: @frontpage-lastbox-margin-bottom;
				}
			}
		}
	}
}

#main {
	.box + .box {
		margin-top:40px;
	}
}
