//TODO: Replace font-awesome icons, mask svg instead.
// Add svg icons to static.stefna.is

a.social {
	&[href*="facebook"]:before {
		.fab();
		content: @fa-var-facebook-f;
	}
	&[href*="twitter"]:before {
		.fab();
		content: @fa-var-twitter;
	}
	&[href*="instagram"]:before {
		.fab();
		content: @fa-var-instagram;
	}
	&[href*="pinterest"]:before {
		.fab();
		content: @fa-var-pinterest;
	}
	&[href*="youtube"]:before {
		.fab();
		content: @fa-var-youtube;
	}
	&[href*="linkedin"]:before {
		.fab();
		content: @fa-var-linkedin;
	}
	&[href*="flickr"]:before {
		.fab();
		content: @fa-var-flickr;
	}
	&[href*="plus.google"]:before {
		.fab();
		content: @fa-var-google-plus;
	}
	&[href*="rss"]:before,
	&[href*="feed"]:before {
		.fas();
		content: @fa-var-rss;
	}
	&:before {
		margin-right: 5px;
	}

	footer .box & {
		display:inline-block;
		vertical-align:top;
		width:60px;
		height:60px;
		font-size: 0;
		border: 1px solid @color-white;
		border-radius: 50%;
		text-align:center;
		margin: 0 14px;
		transition: 0.4s ease background;

		&:before {
			font-size: 22px;
			transition: 0.4s ease color;
			margin:0;
			line-height: 60px;
		}

		&:hover {
			background: @color-white;

			&:before {
				color: var(--color-first);
			}
		}
	}
}
.facebook-social {
	overflow: hidden;
}
