@media print {
	html, body {
		height: 99%;
		margin: 0;
	}

	a[href]:after {
		content: ''
	}

	.entryContent a:after {
		content: " (" attr(href) ")"
	}

	.frontpage a, #mainmenu a {
		text-decoration: none
	}

	.frontpage a[href]:after, .entryContent a.fancybox:after {
		content: ''
	}

	body.loggedIn {
		padding-top: 0
	}

	#mainmenu, #footer, #admin, .jip, footer, header, .entryFooter, .back, #breadcrumbs, #mainmenuToggle, .fb-like, #searchToggle, .paginationControl, #hero, #qn {
		display: none !important;
	}

	body #contentContainer, #main {
		padding: 0 !important;
		border: 0 !important;
		margin: 0 !important;
	}

	.row {
		margin: 0;
	}

	#page #main {
		padding: 0 !important;
		.make-md-column(12, 0);
	}

	#gallery {
		.image {
			width: 200px !important;
			float: left;
			margin: 0 10px 10px 0;

			img {
				max-width: 100%;
			}
		}
		.entry {
			border: 0;
		}
	}
}
