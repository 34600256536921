@import "default";
@import "boxTitle/skoli";
@import "nav/nav";
@import "skoli/shadow";
@import "skoli/searchBox";
@import "skoli/breadcrumbs";
@import "skoli/frontpage";
@import "skoli/btn";
@import "skoli/icons";

@frontpage-gray-color: #f7f7f7;
@header-padding-top: 22px;
@header-border-color: transparent;
@header-margin-bottom: 0;
@qn-text-transform: none;
@breadcrumb-font-size:16px;
@breadcrumb-font-weight:var(--font-weight-normal);
@sidebar-title-font-size: 18px;
@sidebar-title-color: @color-black;
@sidebar-title-transform: none;
@megamenu-position-top: 64px;
@mainmenu-selected-color: var(--color-third);
@iconbox-link-hover-color: var(--color-third);
@footer-text-align: left;
@sidebar-max-width: 250px;
@sidebar-margin-top: 82px;
@qn-font-color: @color-text-light;
@qn-height:46px;

.moya__banner.layout6 {
	--banner-ratio: 38%;
}
