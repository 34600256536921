@iconbox-link-color: @color-text;
@iconbox-link-hover-color: var(--color-first);
@iconbox-link-icon-color: @color-white;
@iconbox-link-icon-background: var(--color-first);
@iconbox-link-icon-hover-color: @color-white;
@iconbox-link-icon-hover-background: @iconbox-link-hover-color;

.icon__box {
	display: flex;
	flex-wrap:wrap;
	justify-content: center;

	.link {
		min-width:160px;
		margin: 20px 0;

		@media (max-width:@screen-xxs-max) {
			width:50%;
			min-width: 0;
		}

		a {
			position: relative;
			display: flex;
			flex-direction: column;
			text-align: center;
			color:@iconbox-link-color;
			padding: 0 15px;

			&:hover {
				color:@iconbox-link-hover-color;

				.icon {
					background-color:@iconbox-link-icon-hover-background;
					border-color:@iconbox-link-icon-hover-background;
					color: @iconbox-link-icon-hover-color;
				}
			}
		}

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width:60px;
			height:60px;
			border-radius:50%;
			background-color:@iconbox-link-icon-background;
			border:1px solid @iconbox-link-icon-background;
			color:@iconbox-link-icon-color;
			font-size:26px;
			line-height:1;
			margin: 0 auto 8px;
			transition:background-color .4s ease, color .4s ease, border-color .4s ease;

			&.svg {
				background-color: @iconbox-link-icon-color;
			}
		}
	}
}
