@mobilemenu-border-color: @color-border;
@mobilemenu-indent: 10px;

// Level 1
@mobilemenu-level1-color: @color-black;
@mobilemenu-level1-bg-color: transparent;
@mobilemenu-level1-selected-color: var(--color-first);
@mobilemenu-level1-selected-bg-color: @color-white;
@mobilemenu-level1-text-transform: none;

// Level 2
@mobilemenu-level2-color: @color-text;
@mobilemenu-level2-bg-color: @color-white;
@mobilemenu-level2-selected-color: var(--color-first);
@mobilemenu-level2-selected-bg-color: none;
@mobilemenu-level2-text-transform: none;

//Level 3
@mobilemenu-level3-color: @color-text;
@mobilemenu-level3-bg-color: @color-white;

//Level 4
@mobilemenu-level4-color: @color-text;
@mobilemenu-level4-bg-color: @color-white;

#mainmenu {
	@media (max-width:@mobilemenu-start-width) {
		li {
			position: relative;

			&:not(.down) {
				> ul {
					display:none;
				}
			}

			a:hover,
			&[class*="selectedLevel"] > a {
				color: var(--color-first);
				font-weight: 500;
			}
		}

		a {
			display:block;
		}

		.mmLevel1 {
			display:block;
			border-bottom: 1px solid @mobilemenu-border-color;
			> li:not(.level1) {
				display: none;
			}
		}

		.mmLevel2 {
			position: relative;
			overflow: hidden;
			background: @mobilemenu-level2-bg-color;
			margin-bottom:-1px;
		}

		.mmLevel3 {
			background:@mobilemenu-level3-bg-color;
		}

		.mmLevel4 {
			background:@mobilemenu-level4-bg-color;
		}

		.level1 {
			display:block;
			width:100%;
			position: relative;
			border-top:1px solid @mobilemenu-border-color;

			> a {
				text-transform: @mobilemenu-level1-text-transform;
				color:@mobilemenu-level1-color;
				font-size: 18px;
				line-height: 34px;
				padding:9px 0 10px;
				margin: 0 30px;
			}
		}

		.level2 {
			font-size:16px;

			> a {
				color: @mobilemenu-level2-color;
				font-weight:400;
				padding: 6px 30px 6px 30px + @mobilemenu-indent;
			}

			> .expand {
				height: 36px;
				&:before {
					top: 14px;
				}
			}
		}

		.level3 {
			a {
				color: @mobilemenu-level3-color;
				padding:5px 15px 5px 45px + @mobilemenu-indent;
			}

			.expand {
				padding: 5px 15px;
			}
		}

		.level4 {
			a {
				color: @mobilemenu-level4-color;
			}
			> a {
				padding-left: 15px + @mobilemenu-indent * 2;
			}
		}

		.level5 {
			> a {
				padding-left: 15px + @mobilemenu-indent * 3;
			}
		}

		.mmLevel6 {
			display:none;
		}

		.selectedLevel1  {
			background:@mobilemenu-level1-selected-bg-color;

			> a {
				background:@mobilemenu-level1-selected-bg-color;
				color: @mobilemenu-level1-selected-color;
			}
		}

		.expand {
			display:block!important;
			position:absolute;
			top:0;
			right:0;
			font-size: 24px!important;
			line-height: 1;
			cursor: pointer;
			color: var(--color-first);
			height: 50px;
			width: 50px;
			border: 0;
			background-color: @mobilemenu-background-color;

			&:before {
				content: '';
				mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 8.02243L6 3.43243L10.59 8.02243L12 6.60243L6 0.60243L0 6.60243L1.41 8.02243Z' fill='%23000000'/%3E%3C/svg%3E");
				background-color: var(--color-first);
				mask-repeat: no-repeat;
				mask-size: contain;
				mask-position: center;
				position: absolute;
				display: inline-block;
				z-index: 1;
				height: 7.5px;
				width: 12px;
				right: 29px;
				top: 23px;
				transform: rotate(180deg);
				transition: transform 0.4s ease;
			}
		}

		.down > .expand {
			&:before {
				transform: rotate(0deg);
			}
		}
	}
}
