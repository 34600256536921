.button,
input.submit,
button.save_design,
input[type="submit"] {
	&:extend(.btn);
	&:extend(.btn-primary);
	color:@color-white;
	background:var(--color-first);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-primary:hover);
		background:var(--color-second);
	}
}


input.cancel,
button.cancel,
button.delete,
button#cancel {
	&:extend(.btn);
	&:extend(.btn-default);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-default:hover);
	}
}

.btn {
	transition: background .4s ease, color .4s ease;
	white-space: inherit;

	&+.btn {
		margin-left:10px;
	}

	&.btn-primary {
		text-transform:uppercase;
		font-size:16px;
		font-weight:var(--font-weight-medium);
		padding: 11px 20px;

		&:hover {
			background:var(--color-second);
		}
		&:focus {
			outline-color: var(--color-second)!important;
		}
	}

	&.btn-secondary {
		color:#fff!important;
		background:var(--color-second);

		&:hover {
			background:@color-black;
		}
	}

	&.btn-border {
		border:1px solid var(--color-first);
		text-transform:uppercase;
		font-size:16px;
		font-weight:var(--font-weight-semibold);
		padding: 8px 20px;

		&:hover {
			background:var(--color-first);
			color:@color-white;
		}
	}
}

@btn-frontpage-primary-bg-color: none;
@btn-frontpage-primary-border-width: 1px;
@btn-frontpage-primary-border-style: solid;
@btn-frontpage-primary-border-color: var(--color-first);
@btn-frontpage-primary-border-radius: 0;
@btn-frontpage-primary-color: var(--color-first);
@btn-frontpage-primary-hover-bg-color: var(--color-first);
@btn-frontpage-primary-hover-border-color: @btn-frontpage-primary-border-color;
@btn-frontpage-primary-hover-color: @color-white;
@btn-frontpage-primary-weight: var(--font-weight-semibold);
@btn-frontpage-primary-padding: 13px 22px 11px;
@btn-frontpage-primary-size: 16px;
@btn-frontpage-primary-transform: uppercase;

.frontpage {
	.btn-primary {
		display:inline-block;
		background:@btn-frontpage-primary-bg-color;
		border-width:@btn-frontpage-primary-border-width;
		border-style:@btn-frontpage-primary-border-style;
		border-color: @btn-frontpage-primary-border-color;
		border-radius:@btn-frontpage-primary-border-radius;
		color:@btn-frontpage-primary-color;
		font-weight:@btn-frontpage-primary-weight;
		padding: @btn-frontpage-primary-padding;
		font-size: @btn-frontpage-primary-size;
		text-transform:@btn-frontpage-primary-transform;

		&:hover {
			background:@btn-frontpage-primary-hover-bg-color;
			border-color: @btn-frontpage-primary-hover-border-color;
			color:@btn-frontpage-primary-hover-color;
		}
	}
}
