.box_multibox {
	.box {
		&:not(:last-child) {
			margin-bottom:40px;
		}
	}
}

.multibox__firstLeft {
	@media (min-width:@screen-md-min) {
		display: flex;
	}

	.left {
		@media (min-width:@screen-md-min) {
			flex: 1 0 auto;
			display: flex;
		}
		@media (max-width:@screen-sm-max) {
			margin-bottom:60px;
		}

		.box {
			@media (min-width:@screen-md-min) {
				width: 100%;
				max-width: 923px;
				border-right: 1px solid fade(@color-black, 20%);
				padding-right: 30px;
			}

			> div {
				@media (min-width:@screen-md-min) {
					max-width: 845px;
				}
			}
		}
	}

	.right {
		@media(max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
			justify-content: center;
			display: flex;
		}
		.box {
			@media(max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
				.make-sm-column(6);
				margin-top: 0!important;
			}

			.boxTitle h2 {
				margin-bottom: 38px;
			}
			&:not(:last-child) {
				@media (min-width: @screen-md-min) {
					padding-top: 13px;
					padding-bottom: 60px;
					border-bottom: 1px solid fade(@color-black, 20%);
					margin-bottom: 78px;
				}
			}
		}
	}

	#links {
		ul {
			.make-row();
		}
		li {
			.make-xs-column(12);

			@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
				width:50%;

				&:last-child:nth-child(odd) {
					width:100%;
				}
			}
			text-align:center;
			margin-bottom:20px;

			> a {
				display: flex;
				border:1px solid #e1e1e1;
				min-height: 128px;
				align-items: center;
				padding: 10px 15px;
			}

			img {
				margin-left:auto;
				margin-right:auto;
			}

			.jip {
				background: none;
				padding: 0;
				right: 25px;
			}
		}
	}
}
