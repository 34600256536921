@qn-text-transform: uppercase;
@qn-position-top: 10px;
@qn-position-right: 15px;
@qn-font-weight: normal;
@qn-text-size: 16px;
@qn-not-first-child-margin-left: 30px;
@qn-font-color: var(--color-first);
@qn-link-color: var(--color-first);
@qn-link-hover-color: var(--color-second);

#qn {
	position: absolute;
	top: @qn-position-top;
	right: @qn-position-right;
	.hidden-sm;
	.hidden-xs;

	> * {
		float:left;
	}

	.mmLevel1 {
		.clearfix();

		li {
			float:left;

			&:not(:first-child) {
				margin-left:@qn-not-first-child-margin-left;
			}

			a {
				text-transform:@qn-text-transform;
				font-size:@qn-text-size;
				font-weight:@qn-font-weight;
				color:@qn-font-color;

				&:hover {
					color:var(--color-first);
				}
			}

			&.book-now {
				a {
					margin-top: -2px;
					font-size:15px;
					font-weight:var(--font-weight-medium);
					display:inline-block;
					color:@color-white;
					padding: 4px 6px 3px;
					border-radius:3px;
					transition:all .4s ease;
					#gradient > .vertical(#dd121e, #990000);

					&:hover {
						#gradient > .vertical(#990000, #990000);
					}
				}
			}
		}
	}
}
