#searchBox {
	@media (min-width:@screen-md-min) {
		margin-top:21px;

		header > #mainmenu & {
			top: 6px;
			margin-top: 0;

			form {
				border-color:@color-white;
			}
		}
	}
}

#searchToggle & {
	header > #mainmenu & {
		@media (min-width:@screen-md-min) {
			color:@color-white;
			margin-top: 6px;
		}
	}
}
