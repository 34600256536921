.quote {
	background: @color-gray;
	border-left: 10px solid @color-border;
	margin: 40px 0 30px 30px;
	padding: 20px 15px;

	&:before {
		.fas();
		content: @fa-var-quote-left;
		display: block;
		text-transform: uppercase;
		color: @color-border;
		font-size: 2em;
		margin-top: -35px;
		margin-bottom: 10px;
	}
}
