.reset {
	ol&,
	ul& {
		list-style:none;
		margin:0;
		padding:0;

		li:before { // Remove bullets added with Font Awesome
			display:none !important;
		}
	}

	a& {
		text-decoration:none;
	}
}

.entryContent {
	h2, h3, h4 {
		&:not(:first-child) {
			margin-top:@default-margin * 2;
		}
	}
	p, ul, ol {
		margin-bottom: @default-margin;
	}
}
