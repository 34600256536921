#divisions {
	.hidden-sm;
	.hidden-xs;

	ul {

		li {
			float:left;
			margin-left:20px;

			&.selected {
				display:none;
			}

			&:not(:first-child) {
				margin-left:10px;

				&:before {
					content:' | ';
					padding: 0 6px 0 0;
				}
			}
			&.selected + li:before {
				display:none;
			}

			a {
				font-size:16px;
				color:currentColor;

				&:hover {
					color:var(--color-first);

					.bannerLayout5 & {
						color:@color-black;
					}
				}
			}

		}
	}
}
