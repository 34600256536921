.btn.btn-primary {
	body.skoli & {
		background:var(--color-first);
		color:@color-white!important;
		border-radius:23px;
		transition:background .4s ease, border-color .4s ease;
		padding: 10px 22px 9px;

		&:hover {
			background:var(--color-second);
			border-color:var(--color-second);
		}
	}
}
