@arrowList-arrow-color: var(--color-first);
@arrowList-arrow-icon:@fa-var-chevron-right;
@arrowList-text-color: @color-text;
@arrowList-text-hover-color: var(--color-first);

.mce-content-body,
.entryContent {
	ul {
		.arrowList();
		margin-left: 10px;
	}

	ol {
		list-style-type: none;
		margin-left: 10px;
		padding-left: 15px;

		li {
			margin-bottom:5px;
		}

		> li {
			counter-increment: count;

			&:before {
				content: counter(count) ". ";
				display: inline-block;
				margin-left: -30px;
				min-width: 30px;
				text-align: right;
				padding-right: 5px;
				color: var(--color-first);
			}
		}

		ol {
			list-style-type: lower-alpha;

			li {
				margin:5px 0;
				counter-increment:none;
				&:before {
					display:none;
				}
			}
		}

	}
}

ul.arrowList {
	.arrowList(@arrowList-arrow-color, @arrowList-arrow-icon, 5px, 30px, 14px);

	a {
		color: @arrowList-text-color;

		&:hover {
			color: @arrowList-text-hover-color;
		}
	}
}
