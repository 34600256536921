a {
	transition: color .4s ease;
	color:var(--color-first);

	&:hover {
		color:var(--color-second);
	}
}

img.responsive {
	.img-responsive();

	visibility:hidden;

	&.loaded {
		visibility:visible;
	}
}

img {
	max-width:100%;
	height:auto;
}

.jip img,
.toolCol img,
img.icon {
	max-width:none;
}

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

.contentWrap {
	.contentWrap();
}

.outerWrap {
	max-width: var(--outer-wrap-max-width, 1920px);
	margin-left: auto;
	margin-right: auto;
}
