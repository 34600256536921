@phoenix-skoli-frontpage-banner-background: #f7f7f7;

.frontpage {
	#banner {
		background: @phoenix-skoli-frontpage-banner-background;

		@media (min-width:@screen-md-min) {
			padding-top: 32px;
		}


		.box_banner ~ .box {
			padding: 20px 0;
		}

	}

	header #mainmenu + #banner {
		@media (min-width:@screen-md-min) {
			padding-top: 1px;
		}
	}
}
