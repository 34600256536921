header {
	.logo {
		a {
			display: flex;
			align-items: center;
			padding: 10px 0;

			@media (min-width: @screen-md-min) {
				padding: 25px 20px 25px 0;
				min-height: 130px;
			}
			@media (max-width: @screen-sm-max) {
				padding: 10px 0;
				float: left;
			}
		}

		img {
			height: 80px;

			@media (max-width:@screen-sm-max) {
				height: 40px;
			}

			&+span {
				@media (min-width:@screen-md-min) {
					margin-left: 20px;
				}
				@media (max-width:@screen-sm-max) {
					margin-left: 10px;
				}
			}
		}

		span {
			color: @color-black;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 2px;

			.bannerLayout4.frontpage &,
			.bannerLayout5.frontpage & {
				color:@color-white;
			}

			@media (min-width:@screen-md-min) {
				font-size: 20px;
			}
			@media (max-width:@screen-sm-max) {
				font-size: 14px;
			}
		}
	}
}
