@import (less) "../../../_moya/js/jquery/ui/css/moya/jquery-ui-1.11.2.min.css";
@import (less) "../../../_moya/js/jquery/fancybox/2.1.4/jquery.fancybox.css";

/** Paths **/
@path-images:'/static/themes/main/images';

/** Bootstrap **/
@import "./node_modules/bootstrap/less/variables";
@import "./node_modules/bootstrap/less/mixins";
@import "./node_modules/bootstrap/less/normalize";
@import "./node_modules/bootstrap/less/scaffolding";
@import "./node_modules/bootstrap/less/type";
@import "./node_modules/bootstrap/less/code";
@import "./node_modules/bootstrap/less/grid";
@import "./node_modules/bootstrap/less/forms";
@import "./node_modules/bootstrap/less/buttons";
@import "./node_modules/bootstrap/less/pagination";
@import "./node_modules/bootstrap/less/utilities";
@import "./node_modules/bootstrap/less/responsive-utilities";
@import "./node_modules/bootstrap/less/responsive-embed";
@import "./node_modules/bootstrap/less/tables";
@import "./node_modules/bootstrap/less/print";

/** Font Awesome **/
@import "./node_modules/@fortawesome/fontawesome-free/less/fontawesome";
@import "../../../_moya/less/fontawesome5";
@fa-font-path: 'https://use.fontawesome.com/releases/v5.3.1/webfonts';

/** Moya frontend styles **/
@import "../../../_moya/less/frontend";
@import "../../../modules/news/static/less/layout";

/** Phoenix - Skoli **/
@import "./extensions/phoenix/less/skoli";

/** Extensions **/
@import "./extensions/mainmenu/less/mega";
@import "./extensions/icon-box/less/default";
@import "./extensions/search-box/less/front";
@import "./extensions/mobilemenu/less/default";
@import "./extensions/mobilemenu/less/layout1/mobilemenu";
@import "./extensions/mobilemenu/less/layout1/mSearchBox";


/** Site theme **/
@import "theme/variables";
@import "theme/logo";

header .logo img {
	filter: hue-rotate(34deg);
}
